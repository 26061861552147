<template>
  <b-tabs class="p-2">
    <b-tab title="ÜST MENÜ" active>
      <draggable
        v-model="topMenu"
        handle=".draggable-sort-item"
        @end="handlerSiraChange(topMenu)"
      >
        <MenuItem
          v-for="(item, index) in topMenu"
          :key="index"
          :item="item"
          :index="index"
          :kategoriler="kategoriler"
          :sayfalar="sayfalar"
          :konum="'top'"
          @show="$emit('show', $event)"
          @refresh="handlerRefreshMenu"
          @siraChange="handlerSiraChange($event)"
          @handlerSubMenuEkle="handlerSubMenuEkle($event)"
        />
      </draggable>
      <b-button
        class="mt-3"
        size="lg"
        squared
        variant="primary"
        @click="handlerMenuEkle('top')"
      >
        <i class="fad fa-plus" />
        Yeni Öğe Ekle
      </b-button>
    </b-tab>
    <b-tab title="ALT MENÜ">
      <draggable
        v-model="bottomMenu"
        handle=".draggable-sort-item"
        @end="handlerSiraChange(bottomMenu)"
      >
        <MenuItem
          v-for="(item, index) in bottomMenu"
          :key="index"
          :item="item"
          :index="index"
          :kategoriler="kategoriler"
          :sayfalar="sayfalar"
          :konum="'bottom'"
          @show="$emit('show', $event)"
          @refresh="handlerRefreshMenu"
          @siraChange="handlerSiraChange($event)"
          @handlerSubMenuEkle="handlerSubMenuEkle($event)"
        />
      </draggable>
      <b-button
        class="mt-3"
        size="lg"
        squared
        variant="primary"
        @click="handlerMenuEkle('bottom')"
      >
        <i class="fad fa-plus" />
        Yeni Öğe Ekle
      </b-button>
    </b-tab>
  </b-tabs>
</template>

<script>
import store from "@/store";
import draggable from "vuedraggable";
import { defineComponent, computed, ref } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import MenuItem from "./component/MenuItem.vue";
import { langs } from "@/libs/languages";
export default defineComponent({
  components: { draggable, MenuItem },
  setup(_, context) {
    const expo = {};
    const toast = useToast();
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.menuler = ref([]);
    expo.topMenu = ref([]);
    expo.bottomMenu = ref([]);
    expo.kategoriler = ref([]);
    expo.sayfalar = ref([]);
    expo.form = ref({
      menu_k_no: null,
      kol_k_no: null,
      konum: "top",
      tip: "kategori",
      turu: "sayfa",
      icerik: {},
      link: false,
    });

    const createIcerikDilUret = () => {
      langs.forEach((dil) => {
        expo.form.value.icerik[dil.lang] = { baslik: "", url: "", slug: "" };
      });
    };

    createIcerikDilUret();

    const handlerFetchAllData = async () => {
      context.emit("show", true);
      await store.dispatch("menuListele");
      await store.dispatch("kategoriListele", { statu: true });
      await store.dispatch("sayfaListele", { statu: true });

      expo.kategoriler.value = store.getters.getKategoriler;
      expo.sayfalar.value = store.getters.getSayfalar;

      expo.handlerRefreshMenu();
      context.emit("show", false);
    };

    expo.handlerRefreshMenu = () => {
      expo.menuler.value = [];
      expo.topMenu.value = [];
      expo.bottomMenu.value = [];
      store.getters.getMenuler.forEach((el) => {
        if (el.menu_k_no == null)
          expo.menuler.value.push({ ...el, children: [] });
      });

      store.getters.getMenuler.forEach((el) => {
        let index = expo.menuler.value.findIndex((x) => x.k_no == el.menu_k_no);
        if (index != -1) {
          expo.menuler.value[index].children.push(el);
        }
      });

      expo.menuler.value.map((el) => {
        el.konum == "top"
          ? expo.topMenu.value.push(el)
          : expo.bottomMenu.value.push(el);
      });
    };

    handlerFetchAllData();

    expo.handlerMenuEkle = (event) => {
      expo.form.value.menu_k_no = null;
      expo.form.value.konum = event;

      store
        .dispatch("menuEkle", expo.form.value)
        .then((res) => {
          if (res.data.success) {
            toast.success("Menu öğe eklendi", { position: "bottom-left" });
            expo.handlerRefreshMenu();
          }
        })
        .catch((err) => {
          toast.error("Hata oluştu.", { position: "bottom-left" });
        });
    };

    expo.handlerSubMenuEkle = (event) => {
      expo.form.value.konum = event.konum;
      expo.form.value.menu_k_no = event.k_no;
      store
        .dispatch("menuEkle", expo.form.value)
        .then((res) => {
          if (res.data.success) {
            toast.success("Menu öğe eklendi", { position: "bottom-left" });
            expo.handlerRefreshMenu();
          }
        })
        .catch((err) => {
          toast.error("Hata oluştu.", { position: "bottom-left" });
        });
    };

    expo.handlerSiraChange = (event) => {
      store
        .dispatch("menuSiraGuncelle", { menuitem: event })
        .then((res) => {
          if (res.data.success == true) {
            toast.success("Sıra güncelleme başarılı.", {
              position: "bottom-left",
            });
          }
        })
        .catch((err) => {
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0
                ? Object.keys(err.data.dup)[0] + " " + err.data.message
                : err.data.message,
              { position: "bottom-left" }
            );
          }
        });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
